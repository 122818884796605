import { CheckboxGroup } from "../../../common/components/CheckboxGroup.js";
import { CampaignSitesConnection } from "../CampaignSitesConnection.js";
import { useIntegrationStore } from "../../../store/IntegrationStore.js";
import { mapState,mapActions } from 'pinia';

const CM360 = {
    name: "CM360Template",
    data: function () {
        return {
            isConnectToAdvertiser: false,
            campaigns: [],
            sites: [],
            sitesOptions: [],
            isLoading: {
                campaigns: false,
                sites: false
            },
            scrollDebounce: true,  // Prevent `onListScroll` to have multiple detection
            campaignPageNumber: 1,
            totalCampaignsPages: 0
        }
    },
    props: {
        ads: [Object, Array],  // Array of objects if we need to connect more than one ads to the platform
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        ...mapActions(useIntegrationStore, [
            'updateIsLastSiteSelectionReady',
            'updateConnectionDetails'
        ]),
        init() {
            this.getCampaigns()
            this.getSites()
        },
        updateConnectionDetailsWithSelected(newSelectedArray){
            const campaignsSites = this.constructSelectedCampaignsSites(newSelectedArray)
            const isAllSiteIdsValid = this.isAllSiteIdsValid(campaignsSites)
              // Create a copy of campaignsSites without the disabled field
            const campaignsSitesWithoutDisabled = JSON.parse(JSON.stringify(campaignsSites)).map(item => {
                delete item.disabled;
                return item;
            });

            if(this.isAdsArray){
                // Construct the selected data into format that matches backend connection API required format
                const organizedSelectedArray = {
                    campaign_sites : campaignsSitesWithoutDisabled // Only bulk connect will need `campaign_sites` as key
                }
                this.updateConnectionDetails(organizedSelectedArray, isAllSiteIdsValid)
            }else{
                this.updateConnectionDetails(campaignsSitesWithoutDisabled, isAllSiteIdsValid)
            }
        },
        isAllSiteIdsValid(array) {
            // Ignore disabled campaigns
            return array.filter(item => !item.disabled).every(item => 
                item.site_ids && 
                item.site_ids.length > 0
              );
        },
        constructSelectedCampaignsSites(dataArray){
            // Transform the keys of each object in the array to match backend format
            return dataArray.map(item => ({
                campaign_id: item.campaignId, // Rename 'campaignId' key to 'campaign_id'
                site_ids: item.siteIds.map(site => site.value), // Extract all 'value' from array of objects inside 'siteIds'
                disabled: item.disabled // Add disabled field here so we can ignore expired campaigns when we check isAllSiteIdsValid
            }));
        },
        constructCampaignsOptions(dataArray) {
            let adsCampaignIds
            // Preselect the connected campaign only if the user has selected a single ad
            if(!this.isAdsArray) {
                // Find the platform object with type 'gcm'
                const gcmCampaigns = this.ads.platforms.find(platform => platform.type === this.platformNameMap.GCM)?.data;
                // Convert this.ads.campaigns to a Set for efficient lookup
                if(gcmCampaigns) adsCampaignIds = new Set(gcmCampaigns.map(campaign => campaign.campaign_id));
            }

            // Get campaigns that connected with ad but not in dataArray (campaigns list)
            const campaignsWithExpired = [...dataArray , ...this.findExpiredCampaigns(dataArray)]
            // Sort the campaigns first by whether they are checked, then by name
            const sortedCampaigns = this.sortCampaignsByConnected(campaignsWithExpired, adsCampaignIds)
            // Sort the array to prioritize disabled campaigns by placing them at the top
            const sortedCampaignsWithDisabledAtTop = this.sortCampaignsByDisabledValue(sortedCampaigns)

            return sortedCampaignsWithDisabledAtTop.map(campaign => ({
                id: campaign.id,
                name: campaign.name,
                checked: this.shouldCampaignChecked(campaign, adsCampaignIds),
                disabled: campaign.disabled
            }))
        },
        shouldCampaignChecked(campaign, adsCampaignIds){
            // true if the campaign's id is in adsCampaignIds or disabled, false otherwise
            return this.isAdsArray ? false : campaign.disabled ? true : adsCampaignIds?.has(campaign.id)
        },
        // Prioritize connected campaigns at the top
        sortCampaignsByConnected(dataArray, adsCampaignIds) {
            return dataArray.sort((a, b) => {
                if (this.isAdsArray || !adsCampaignIds?.has(a.id)) {
                    // Campaigns that are not checked or in bulk mode
                    return 0;
                } else if (!adsCampaignIds?.has(b.id)) {
                    // Campaigns that are checked but not in bulk mode
                    return -1;
                } else {
                    // Both campaigns are checked and in bulk mode
                    return a.name.localeCompare(b.name);
                }
            });
        },
        // Put campaigns with `disabled = true` to the top of the list
        sortCampaignsByDisabledValue(dataArray){
            return dataArray.reduce((acc, curr) => { 
                if (curr.disabled) {
                    acc.unshift(curr);
                } else {
                    acc.push(curr);
                }
                return acc;
            }, []);
        },
        // Expired campaigns associated with the ad will not be included in `campaignsOptions`. 
        // These campaigns should be displayed as disabled, with the sole option for the user to disconnect them from the ad
        findExpiredCampaigns(dataArray) {
            if(this.isAdsArray) {
                return [] // Connected campaigns will not be pre-selected in bulk connect mode
            }else {
                // Find the platform object with type 'gcm'
                const gcmConnectedCampaigns = this.ads.platforms.find(platform => platform.type === this.platformNameMap.GCM)?.data;
                if(!gcmConnectedCampaigns) return [] // The ad did not connect to GCM before

                // Find campaigns in gcmConnectedCampaigns that are not in campaignsOptions  (possibly expired/inactive campaigns)
                const expiredCampaigns = gcmConnectedCampaigns.filter(connectedCampaign => !dataArray.some(option => option.id === connectedCampaign.campaign_id));

                // Transform to the appropriate format required for the `constructCampaignsOptions()` function to utilize in the creation of options
                return expiredCampaigns.map(campaign => ({
                    id: campaign.campaign_id,
                    name: campaign.campaign_name,
                    disabled: true
                }))
            }
        },
        constructSitesOptions() { // Option structured for vueform multiselect
            this.sitesOptions = this.sites.map(site => ({
                value: site.id,
                label: site.name,
            })).sort((a, b) => a.label.localeCompare(b.label));
            this.isLoading.sites = false
        },
        getCampaigns() {
            this.isLoading.campaigns = true

            const params = {};
            if (this.campaignPageNumber > 1 ) {
                params.page = this.campaignPageNumber
            }

            this.integrationsService.gcm.getCampaigns(this.platform.type, params).then(response => {
                if(this.totalCampaignsPages !== response.total_pages) this.totalCampaignsPages = response.total_pages
                if(this.campaignPageNumber > 1) {
                    this.campaigns = this.campaigns.concat(...this.constructCampaignsOptions(response.results)) // Concat new page advertisers into old advertisers array
                }else{
                    this.campaigns = this.constructCampaignsOptions(response.results);
                }
                this.isLoading.campaigns = false
            })
        },
        getSites() {
            this.isLoading.sites = true
            this.integrationsService.gcm.getSites(this.platform.type).then(response => {
                this.sites = response
                this.constructSitesOptions();
            })
        },
        onListScroll({ target: { scrollTop, scrollHeight, offsetHeight } }) {
            if ((Math.ceil(scrollTop + offsetHeight) >= scrollHeight - 20) && this.scrollDebounce) {
                this.scrollDebounce = false;
                this.getMoreCampaigns()

                // Handle set list detection of scroll to bottom
                let _this = this;
                setTimeout(function () {
                    if (!_this.scrollDebounce) {
                        _this.scrollDebounce = true
                    }
                }, 1000); // To prevent multiple call
            }
        },
        getMoreCampaigns() {
            if(this.campaignPageNumber === this.totalCampaignsPages) return
            this.campaignPageNumber++;
            this.getCampaigns();
        },
    },
    mounted () {
        this.init()
    },
    components: {
        CheckboxGroup,
        CampaignSitesConnection
    },
    watch: {
        isConnectToAdvertiser(newValue) {
            this.$emit('updateIsConnectToAdvertiser', newValue)
        },
    },
    computed: {
        ...mapState(useIntegrationStore, ['getIsLastSiteSelectionReady', 'platformNameMap']),
        isAdsArray() {
            return Array.isArray(this.ads)
        }
    },
    destroyed () {
        // Reset store value
        this.updateIsLastSiteSelectionReady(false);
    },
    template:
    `
    <div class="cm360-connection-template accordion" role="tablist" >
        <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': getIsLastSiteSelectionReady}">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-card no-body class="mb-1 border-0 rounded-0" @scroll="onListScroll">
            <b-card-header header-tag="header" v-b-toggle.campaigns-accordion class="p-2 border-0 rounded-0 grass-background d-flex justify-content-between align-item-center" role="tab">
                <p class="m-0 text-left text-white font-weight-600">Campaigns</p>
                <font-awesome-icon class="arrow-down light-sage-color align-self-center" :icon="['fas', 'chevron-down']" />
            </b-card-header>
            <b-collapse id="campaigns-accordion" visible role="tabpanel">
                <b-card-body class="position-relative">
                    <CampaignSitesConnection :campaignsOptions="campaigns" :sitesOptions="sitesOptions" valueField="id" @updateSelected="updateConnectionDetailsWithSelected" :ads="ads" :isAdsArray="isAdsArray" />
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
    `
}

export { CM360 };
