/**
 * Flow:
 * 1. Get businesses list
 * 2. Save integration details when user clicks 'Connect'
 */
const Meta = {
    name: 'Meta',
    data: function () {
        return {
            businesses: [],
            selected: {
                business_id: null,
                business_name: null
            },
            isInit: false, // Hide form if account list if not fetched
            searchQuery: "",
            isLoading: {
                businesses: false,
            },
        }
    },
    props: {
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        getBusinesses() {
            this.integrationsService.meta.getBusinesses(this.platform.type).then(response => {
                this.businesses =  this.constructBusinessesOptions(response.businesses)
                if(this.businesses.length === 1 && !this.selected.business_id) {
                    this.selected.business_id = this.businesses[0].value // Name will be updated in constructDetails()
                }else{
                    this.isLoading.businesses = false
                }
                this.isInit = true
            })
        },
        constructBusinessesOptions(dataArray) {
            return dataArray.map(item => ({
                value: item.id,
                text: item.name
            }));
        },

        constructDetails() {
            // Add business name to DB data field for later display
            this.selected.business_name = this.businesses.find(business => business.value === this.selected.business_id).text
            return {
                type: this.platform.type,
                data: { ...this.selected }
            }
        },
    },
    computed: {
        isAllSelected() {
            // Extract all values from the `selected` object
            const selectedValues = Object.values(this.selected);
            // Check if all extracted values are not null
            return selectedValues.every(value => value !== null);
        }
    },
    watch: {
        isAllSelected(newValue, oldValue) {
            this.$emit("updateFormReadystate", newValue)
        },
        selected: {
            handler: function (newSelected, oldSelected) {
                this.$emit('updateIntegrationDetails', this.constructDetails());
            },
            deep: true // Watch nested properties
        },
    },
    mounted() {
        this.getBusinesses();
    },
    template:
        `
    <div class="border p-4 rounded">

        <div v-if='!isInit' class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': isInit}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <b-form v-else="isInit">
            <div class="mb-2 position-relative">
                <label>Business</label>

                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.businesses}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>

                <b-form-select v-model="selected.business_id" :options="businesses">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Select business</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </b-form>
    </div>
    `
}

export { Meta };
