import { FeedStatus } from "./FeedStatus.js"
import { useIntegrationStore } from "../../store/IntegrationStore.js";
import { mapState } from 'pinia';

const IntegrationSwitch = {
    name: "IntegrationSwitch",
    props: {
        platform: Object,
        staticUrl: String,
        websiteName: String,
        integrationsService: Object
    },
    data() {
        return {
            checked: null,
            description: { // Using platform.type as key
                gcm: 'Manage and track digital marketing campaigns with advanced reporting across various channels.',
                meta: 'Manage and optimize social media marketing campaigns with detailed audience targeting on Meta.',
                google_ads: 'Create, manage, and optimize online ad campaigns across Google\'s search and display networks.',
                merchant_center: 'Upload and maintain product information for Google Shopping ads and other Google services.'
            },
            oauthUrl: null,
            pollingInterval: 10000, // 10 seconds
            polling: null,
            feedDetails: null,
            getFeedTimeTries: 0,
            isShowStatusModal: false,
            integrationStore: useIntegrationStore()
        }
    },
    destroyed () {
        clearInterval(this.polling);
    },
    methods: {
        onChangeSwitch(){
            if(this.isAuthenticationNeeded) {
                this.authenticate()
            } else {
                this.showIntegrationModal()
            }
        },
        showIntegrationModal(isConnect = !this.checked) {
            this.$emit("showIntegrationModal", this.platform, isConnect)
        },
        updateCheckedValueWithPlatform() {
            if(!this.platform) return
            if(this.isPlatformPending || this.platform.status === this.platformStatusMap.ENABLED) this.checked = true
            if(this.platform.status ===  this.platformStatusMap.DISABLED) this.checked = false
        },
        isConfigNeeded() {
            return this.platform.status === this.platformStatusMap.NEEDS_CONF
        },
        authenticate() {
            if(this.platform.type == 'google_ads') return // TODO Remove this after google ads is workin

            this.integrationsService.getOauth(this.platform.type).then(response => {
                if(this.platform.type === 'meta') {
                    const platform = response.data.platform;
                    this.meta_login(platform);
                    return;
                }
                window.open(response.data.oauth_url, '_blank');
            });
        },
        meta_login(platform) {
            FB.login((response) => {
                if (response.authResponse) {
                    let accessToken = response.authResponse.accessToken;
                    const params = {
                        code: accessToken,
                        settings: 'catalog_management,business_management',
                        businesses: platform.data.businesses
                    };
                    this.integrationsService.meta.saveOauth(platform.id, params).then(response => {
                        this.$router.push({ name: 'integrations-preOpenModal', params: { platformType: platform.type } })
                    });
                } else {
                    // TODO frontend need to handle this
                    console.log("Authorization failed. Please try again to connect AdRetriever to your Meta account.")
                }
            }, {
                scope: 'catalog_management,business_management'
            });
        },
        getFeedStatus() {
            if (this.platform.type == 'google_ads' || this.platform.type === 'gcm') return
            if (this.isPlatformNotReadyForFeed) return // Platform not enabled, stop getting feed

            try {
                const params = {
                    expand: 'settings',
                    only: 'settings'
                };

                this.integrationsService.getIntegration(this.platform.type, params).then(response => {
                    this.feedDetails = response.settings

                    if (this.platform.status === this.platformStatusMap.ENABLING) {

                        if (this.polling) return
                        this.polling = setInterval(async () => {
                            this.feedDetails = await this.getFeedStatus();
                            this.getFeedTimeTries += 1;
                        }, this.pollingInterval);
                    } else {
                        clearInterval(this.polling);
                    }
                })
            } catch (e) {
                console.error(e);
                return {}
            }
        },
        onContentClick() {
            this.isShowStatusModal = true
        },
        hideStatusModal() {
            this.isShowStatusModal = false
        },
    },
    computed: {
        ...mapState(useIntegrationStore, [
            'getNeedAuthStatuses',
            'getNotReadyForFeedStatuses',
            'getPendingStatuses',
            'platformStatusMap',
            'isPlatformHasFeedDetails'
        ]),
        // Control the state of the switch 
        isAuthenticationNeeded(){
            return this.getNeedAuthStatuses.includes(this.platform.status)
        },
        // Show needs auth warning only when status is need_auth (ignore disabled platform)
        isReAuthNeeded() {
            return this.platform.status === this.platformStatusMap.NEEDS_AUTH
        },
        feedStatusProps() {
            return {
                platform: this.platform,
                websiteName: this.websiteName,
                feedDetails: this.feedDetails,
                getFeedTimeTries: this.getFeedTimeTries,
                // platformStatusMap: this.platformStatusMap,
                isShowStatusModal: this.isShowStatusModal
            }
        },
        isPlatformPending() {
            return this.getPendingStatuses.includes(this.platform.status)
        },
        isPlatformNotReadyForFeed() {
            return this.getNotReadyForFeedStatuses.includes(this.platform.status)
        }
    },
    watch: {
        platform: { // Check if platform as props is passed in
            deep: true,
            handler(newValue, oldValue) {
                this.updateCheckedValueWithPlatform()
                if(newValue.status !== oldValue.status) this.getFeedStatus() // Compare status to prevent call getFeedStatus twice
            }
        }
    },
    components: {
        FeedStatus
    },
    mounted() {
        this.updateCheckedValueWithPlatform()
        this.getFeedStatus()
    },
    template: `
    <b-card class="col-lg-4 col-md-12 col-sm-12 mb-4 rounded-xl shadow" body-class="px-2">
        <b-card-text class="d-flex flex-column h-100 justify-content-between">
            <div>
                <div class="row justify-content-between mb-3 mt-2">
                    <div class="col-3">
                        <img class="platform-icon w-75" :src="staticUrl + 'img/' + platform.type + '-logo.png'" />
                    </div>
                    <b-form-checkbox class="integration-switch" :class="[{'checked': checked }, {'pending' : isPlatformPending}]"  v-model="checked" name="check-button" switch @click.native.prevent="onChangeSwitch" key="platform.id" />
                </div>
                <div v-if="isConfigNeeded()" class="row no-gutters mb-3">
                    <b-button variant="outline" class="marigold-v-light-background rounded-pill font-size-sm px-3 py-2" @click="showIntegrationModal(true)">
                        <font-awesome-icon class="marigold-v-color mr-2" :icon="['fas', 'triangle-exclamation']" />
                        Needs Configuration
                    </b-button>
                </div>
                <div v-if="isReAuthNeeded" class="row no-gutters mb-3">
                    <b-button variant="outline" class="marigold-v-light-background rounded-pill font-size-sm px-3 py-2" @click="authenticate">
                        <font-awesome-icon class="marigold-v-color mr-2" :icon="['fas', 'triangle-exclamation']" />
                        Needs Authentication
                    </b-button>
                </div>
                <div @click="onContentClick()">
                    <p class="font-weight-bold mb-0">{{ platform.name }}</p>
                    <p class="font-size-sm mb-0">{{ description[platform.type] }}</p>
                </div>
            </div>
            <FeedStatus v-if="isPlatformHasFeedDetails(platform)" v-bind="{ ...feedStatusProps }" @hideModal="hideStatusModal" />
        </b-card-text>
    </b-card>
    `
}

export { IntegrationSwitch };