// Common component can be reused to list out checkbox options with select all function
const CheckboxGroup = {
    name: "CheckboxGroup",
    data() {
        return {
           selected: []
        }
    },
    mounted () {
        this.initializeSelected();
    },
    methods: {
        initializeSelected() {
            // Initialize selected array based on options
            this.options.forEach(option => {
                if (option.checked) {
                    this.selected.push(option.id);
                }
            });
        },
        toggleSelectAll() {
            if (this.isSelectedALL) {
                // Deselect all
                this.selected = [];
            } else {
                // Select all
                this.selected = this.options.map(option => option.id);
            }
        },
        updateSelection(selectedOptions) {
            this.selected = selectedOptions;
        },
    },
    computed: {
        selectUnselectAllText() {
            // Dynamically determine the text based on whether all options are selected
            return this.isSelectedALL ? 'Un-select All' : 'Select All';
        },
        isSelectedALL() {
            // Compute whether all options are selected
            return this.options && this.selected.length === this.options.length;
        }
    },
    watch: {
        selected(newValue) {
            // Update parent selected array
            this.$emit("updateSelected", newValue)           
        }
    },
    props: {
        options: Array, //campaignsOptions
        groupLabel: String,
        ariaDescribedBy: String,
        valueField: String
    },
    template: `
    <b-form-group>
        <template #label>
            <p class="m-0">{{ groupLabel }}</p>
            <b-form-checkbox
            :checked="isSelectedALL"
            @change="toggleSelectAll"
            aria-describedby="ariaDescribedBy"
            aria-controls="campaigns"
            >
                <span class="font-weight-normal">{{ selectUnselectAllText }}</span>
            </b-form-checkbox>
        </template>

        <template v-slot="{ ariaDescribedBy }">
            <b-form-checkbox-group
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedBy"
            :name="ariaDescribedBy"
            class="ml-4"
            label-class="font-weight-normal"
            stacked
            :value-field="valueField"
            text-field="name"
            @change="updateSelection"
            ></b-form-checkbox-group>
        </template>
    </b-form-group>

    `,
}

export { CheckboxGroup }

