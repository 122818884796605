import { convertDatetimeToRelativeTime } from '../../../utils/date_utils.js';
import { useIntegrationStore } from '../../../store/IntegrationStore.js';
import { mapState } from 'pinia';

const MerchantCenter = {
  name: "MerchantCenterFeedStatusTemplate",
  data() {
    return {
      loadingStatus: null,
      integrationStore: useIntegrationStore()
    }
  },
  props: {
    getFeedTimeTries: Number,
    feedDetails: Object,
    platform: Object,
    // Status display
    statusDisplayValue: String,
    icon: Array,
    iconColorClass: String,
    isEnabling: Boolean
  },
  methods: {
    convertTimestamp(timestamp) {
      return convertDatetimeToRelativeTime(timestamp)
    }
  },
  computed: {
    ...mapState(useIntegrationStore, ['platformStatusMap']),
    isLoading: function () {
      if (!this.feedDetails) {
        this.loadingStatus = "Getting Feed Status"
        return true
      } else if (this.platform.status === this.platformStatusMap.ENABLING) {
        this.loadingStatus = "Creating Feed"
        return true
      }
      return false
    }
  },
  template:
  `
    <div class="merchant-center-feed-status-template feed-status-template">
      <h6>
        <span>Feed status: </span>
        <font-awesome-icon
          v-if="icon.length"
          class="mr-2"
          :icon="icon"
          :class="iconColorClass"
          :spin="isEnabling"
        />
        <span>{{ isLoading ? loadingStatus : statusDisplayValue }}</span>
      </h6>

      <div v-if="getFeedTimeTries >= 10">
        <div class="alert alert-warning mb-0" role="alert">
          It is taking a longer amount of time than expected to create the feed. We
          might have encountered an unknown error. If this persists please contact
          support.
        </div>
      </div>

      <div class="">
        <div v-if="isLoading" class="w-100" variant="outline">
          <p class="loading"></p>
        </div>

        <div v-else>
          <div class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">Merchant:</p>
            <p class="mb-0">{{ platform.data.merchant_id }}</p>
          </div>

          <div class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">Last feed update:</p>
            <p class="mb-0">{{ convertTimestamp(feedDetails?.last_upload) }}</p>
          </div>

          <div class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">Total items:</p>
            <p class="mb-0">{{ feedDetails?.total_items }}</p>
          </div>

          <div class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">Items added:</p>
            <p class="mb-0">{{ feedDetails?.valid_items }}</p>
          </div>

          <div class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">Items rejected:</p>
            <p class="mb-0">{{ feedDetails?.total_items - feedDetails?.valid_items }}</p>
          </div>
       
          <div class="mt-4">
            <div v-if="feedDetails?.errors && !isLoading">
                <b-alert v-for="error in feedDetails?.errors" :key="error.code" dismissible variant="danger" show>
                  <span class="text-capitalize">{{ error.code }}: </span><span>{{error.message}}</span>
                </b-alert>
            </div>

            <div v-if="feedDetails?.warnings && !isLoading">
              <b-alert v-for="warning in feedDetails?.warnings" :key="warning.code" dismissible variant="warning" show>
                <span class="text-capitalize">{{ warning.code }}: </span><span>{{warning.message}}</span>
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </div>

  `,
}

export { MerchantCenter };
