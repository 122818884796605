import { defineStore } from 'pinia';

export const useIntegrationStore = defineStore('integration', {
    state: () => ({
        /** Platform Enums */
        platformStatusMap: { // In app using : API response
            DISABLED: 'disabled',
            NEEDS_AUTH: 'needs_auth',
            NEEDS_CONF: 'needs_conf',
            ENABLING: 'enabling',
            ENABLED: 'enabled',
            ERROR: 'error'
        },
        platformNameMap: { // In app using : Backend enum
            META: 'meta',
            GCM: 'gcm',
            MC: 'merchant_center',
            GOOGLE_ADS: 'google_ads',
        },
        /** Site Selection (GCM only) */
        defaultSiteSelections: [], // Keep track of user's selection for site and pre-select site for each campaign that checked
        isLastSiteSelectionReady: false, // Track if last of the site's  Multiselect is ready to hide loading overlay in modal
        /** Connection details */
        connectionDetails: { // Store selected platform's Ad connection details for future use in the connection API
            isAllConnectionDetailsValid: true, // Determine whether to disable the `Update Connection` option in the connection modal
            isConnect: null, // For Meta and MC ONLY as they have checkbox in connection modal to toggle connect / disconnect
            data: [] // Each platform would has different format inside `data`
        }, 
    }),
    actions: {
        isPlatformHasFeedDetails(platform) {
            return this.getEligibleFeedStatuses.includes(platform.status)
        },
        /**
         * @param {Array[String]} newSites 
         */
        updateDefaultSiteSelections(newSites) {
            if (this.isDefaultSiteSelectionsChanged(newSites)) this.defaultSiteSelections = newSites
        },
        isDefaultSiteSelectionsChanged(newSelections) {
            return JSON.stringify(this.defaultSiteSelections) !== JSON.stringify(newSelections);
        },
        updateIsLastSiteSelectionReady(value) {
            this.isLastSiteSelectionReady = value
        },
        /**
         * Updates connection details based on the provided data
         * 
         * @param {Array[]|Array[String]|Array[Object]|null} data
         *     Can be one of the following types:
         *     - Array[]: For disconnect single ad
         *     - Array[String]: '["enabled"]' for MC and Meta to connect ad
         *     - Array[Object]: Used by GCM only, contain array of campaign id and sites id objects
         *     - null: We do not require any data for bulk action
         * 
         * @param {boolean} isAllConnectionDetailsValid
         *     Indicating whether all connection details are valid
         * 
         * @param {boolean} isConnect
         *     Optional boolean value for platforms requiring connect/disconnect functionality
         * 
         */
        updateConnectionDetails(data, isAllConnectionDetailsValid, isConnect) {
            // Check if data matches one of the four possible input types
            if (!this.isDataValid(data)) {
                throw new Error('Invalid data type for updateConnectionDetails(). Expected empty array, array of strings, array of objects, or null.');
            }

            this.connectionDetails.data = data
            this.connectionDetails.isAllConnectionDetailsValid = isAllConnectionDetailsValid
            this.connectionDetails.isConnect = isConnect
        },
        // Only used by updateConnectionDetails to check if `data` is valid
        isDataValid(data) {
            return (
                data === null ||
                Array.isArray(data) &&
                (data.length === 0 ||
                    (typeof data[0] === 'string' && data.every(item => typeof item === 'string')) ||
                    (typeof data[0] === 'object' && data.every(item => typeof item === 'object')))
            );
        }
    },
    getters: {
        // For IntegrationSwitch to use to determine toggle to show pending state
        getPendingStatuses: (state) => {
            return [
                state.platformStatusMap.NEEDS_AUTH,
                state.platformStatusMap.NEEDS_CONF,
                state.platformStatusMap.ENABLING,
                state.platformStatusMap.ERROR
            ]
        },
        // For IntegrationSwitch to determine toggle on click to trigger authentication
        getNeedAuthStatuses: (state) => {
            return [
                state.platformStatusMap.NEEDS_AUTH,
                state.platformStatusMap.DISABLED
            ]
        },
        // For IntegrationSwitch to determine get feed details or not when mounted
        getNotReadyForFeedStatuses: (state) => {
            return [
                state.platformStatusMap.NEEDS_AUTH,
                state.platformStatusMap.NEEDS_CONF,
                state.platformStatusMap.DISABLED
            ]
        },
        // Statuses that feed details should be available to show feed status
        getEligibleFeedStatuses: (state) => {
            return [
                state.platformStatusMap.ENABLING,
                state.platformStatusMap.ENABLED,
                state.platformStatusMap.ERROR
            ]
        },
        // Vue WILL add  [__ob__: Observer] into the return value and make it becomes object instead of array, use JSON.parse with JSON.stringify to solve that issue
        getDefaultSiteSelections: (state) => {
            return JSON.parse(JSON.stringify(state.defaultSiteSelections))
        },
        getIsLastSiteSelectionReady: (state) => {
            return state.isLastSiteSelectionReady
        },
        // For separate platforms in groups in Connection modal, return object of two arrays to group platform by their platform.type
        getPlatformCategoryGroups : (state) => {
            return {
                ads: [
                    state.platformNameMap.GOOGLE_ADS,
                    state.platformNameMap.GCM,
                ],
                catalog: [
                    state.platformNameMap.META,
                    state.platformNameMap.MC,
                ]
            }
        },
        // Returns only the necessary data to be sent to the connections API
        getConnectionDetailsData : (state) => {
            return state.connectionDetails.data
        },
        getIsAllConnectionDetailsValid : (state) => {
            return state.connectionDetails.isAllConnectionDetailsValid
        },
        getIsConnect : (state) => {
            return state.connectionDetails.isConnect
        },
    }, 

});
