// Integration connection modal
import { CM360 } from "./IntegrationModalTemplates/CM360.js"
import { StatusAlert } from "../../common/components/StatusAlert.js"
import {MerchantCenter} from "./IntegrationModalTemplates/MerchantCenter";
import { Meta } from "./IntegrationModalTemplates/Meta";

const IntegrationModal = {
    data: function () {
        return {
            isLoading: false,
            // Error control
            isSuccess: false,
            isShowAlert: false,
            platformTemplateByType: {
                // google_ads: GoogleAds,
                gcm: CM360,
                meta: Meta,
                merchant_center: MerchantCenter
            },
            integrationDetails: {
                type: null,
                data: {}
            },
            isFormReady: false
        }
    },
    props: {
        isShow: Boolean,
        isConnect: Boolean,
        platform: Object,
        staticUrl: String,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        disablePlatform() {
            this.integrationsService.disable(this.platform.type).then(response => {
                this.$emit("showSuccessAlert", "Platform disabled.")
                this.$emit('hideModal')
                this.isShowDisableConfirmation = false
            }).catch(err => {
                this.isShowDisableConfirmation = false
            });
        },
        closeModal(bvModalEvent, isCancelled = false) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.$emit('hideModal', isCancelled)
        },
        // Update integrationDetails from child component when user select changes
        updateIntegrationDetails(details) {
            this.integrationDetails = Object.assign({}, details)
        },
        OnConnect() {
            this.integrationsService.update(this.platform.type, {
                ...this.integrationDetails
            }).then(response => {
                    this.$emit("showSuccessAlert", "Platform enabled.")
                    this.$emit('hideModal')
                }
            ).catch(err => {
                this.$emit("showErrorAlert", err.response.data.message)
            });
        },
        updateFormReadystate(newState) {
            this.isFormReady = newState
        }
    },
    computed: {
        platformConnectionTemplateProps() {
            return {
                platform: this.platform,
                websiteName: this.websiteName,
                integrationsService: this.integrationsService
            }
        }
    },
    components: {
        StatusAlert
    },
    template:
    `
    <b-modal 
    v-model="isShow" 
    id="integration-modal"
    size="md"
    centered
    no-close-on-backdrop
    no-close-on-esc
    @cancel="(e) => closeModal(e, true)"
    @close="(e) => closeModal(e, true)">
        <template #modal-title>
            <div class="row no-gutters">
                <div class="col-1 d-inline no-gutters text-right mr-2">
                    <img class="platform-icon w-50" :src="staticUrl + 'img/' + platform.type + '-logo.png'" />
                </div>
                <span v-if="isConnect">Connect To  {{ platform.name }}</span>
                <span v-else>Disconnect {{ platform.name }}</span>
            </div>
        </template>

        <!-- modal-body Start -->
        <StatusAlert :isSuccess="isSuccess" :isShowAlert="isShowAlert" />

        <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': !isLoading}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <div v-if="isConnect" class="modal-body">
            <div>
                <p class='spruce-color'>Enter your credentials to enable the integration.</p>
                <component
                :is="platformTemplateByType[platform.type]"
                v-bind={...platformConnectionTemplateProps}
                @updateIntegrationDetails="updateIntegrationDetails"
                @updateFormReadystate="updateFormReadystate"
                />
            </div>
        </div>

        <div v-else>
            <p>Are you sure you want to disconnect this integration?</p>
        </div>
        <!-- modal-body End -->

        <template #modal-footer>
            <b-button class="btn-spruce-outline m-1" @click="(e) => closeModal(e, true)" :disabled="isLoading">
                Cancel
            </b-button>
            <b-button
            :disabled="isLoading || !isFormReady"
            v-if="isConnect" 
            class="btn-spruce m-1"
            @click="OnConnect">
                Connect
            </b-button>

            <b-button v-else class="btn-spruce m-1" :disabled="isLoading" @click="disablePlatform">
                Disconnect
            </b-button>
        </template>
    </b-modal> 
    `
}

export { IntegrationModal };
