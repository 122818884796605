import Vue from 'vue'
import VueRouter from 'vue-router'
import {UserService} from "./common/api.service.js";
import ApiService from './common/api.service.js';
import { isEqual, debounce } from 'lodash';

Vue.use(VueRouter)
/**
 * This is so we can route and push states. Hash router makes it so we dont collide with django
 */
const router = new VueRouter({
    mode: 'hash',
})

let BaseList = {
    router,
    props: {
        fields: Array[Object],
        resource: String,
        urlTemplate: {
            type: String,
            default: ""
        },
        data: {
            type: Object,
            default: () => {
            }
        },
        filters: {
            type: Object,
            default: () => {}
        },
        expand: String,
        only: String,
        exclude: String,
        sortDesc: Boolean,
        sortBy: String,
        pollingInterval: Number,
        userId: String,
        searchQuery: String
    },
    data: function () {
        let dataCell = "None";
        if (this.data && 'linkField' in this.data) {
            dataCell = this.data.linkField
        }
        return ({
            loading: false,
            totalRows: 1,
            currentPage: 1,
            rows: 0,
            linkCell: "cell(" + dataCell + ")",
            items: [],
            polling: null,
            sortDesc_: this.sortDesc,
            sortBy_: this.sortBy,
            filters_: this.filters,
            perPage: 15,
            perPageOptions: [15, 25, 50, 100],
        })
    },
    created: async function () {
        await this.refresh();
        if (Object.keys(this.$route.query).length > 0) {
            await this.updateBasedOnRouter(this.$route);
        } else {
            this.sortDesc_ = '';
            this.sortBy_ = '';
            this.currentPage = 1;
        }
        this.pollAds()
    },
    methods: {
        async pollAds(delay=this.pollingInterval) {
          let ctx = {
              sortDesc: this.sortDesc_,
              sortBy: this.sortBy_
          }
          setTimeout(async () => {
            this.items = await this.apiProvider(ctx)
            const isAdInProgress = !this.items.map((item) => item.status).every(this.isReadyOrErr)
            if (isAdInProgress) {
                this.pollAds()
            } else {
                this.pollAds(Math.min(delay*2, 20000))
            }
          }, delay)
        },
        async apiProvider(ctx) {
            try {
                let params = {
                    page: ctx?.currentPage ?? this.currentPage,
                    page_size: this.perPage,
                    expand: this.expand,
                    only: this.only,
                    exclude: this.exclude,
                    ...this.filters_
                }
                if (ctx.filters !== undefined) {
                    params = {...params, ...ctx.filters}
                }
                this.sortDesc_ = ctx?.sortDesc ||  this.sortDesc_
                this.sortBy_ = ctx?.sortBy || this.sortBy_
                if (this.sortDesc_ !== undefined && this.sortBy_) {
                    // Add id column as ordering tie-breaker
                    let orderingKeys = [this.sortBy_, 'id'].map(key => this.sortDesc_ ? `-${key}` : key);
                    params.ordering = orderingKeys.join(',')
                }
                if(this.searchQuery?.length) {
                    params.search = this.searchQuery
                }
                const response = await ApiService.query(this.resource, params);
                this.loading = false;
                this.rows = response.count;
                this.$emit("updateAdCount", response.count)
                return response.results;
            } catch (e) {
                console.error(e)
                return []
            }
        },
        async sortChanged(ctx) {
            if (ctx.sortDesc !== this.sortDesc_ || ctx.sortBy !== this.sortBy_) {
                let query = {...this.$route.query, sortDesc: ctx.sortDesc, sortBy: ctx.sortBy}
                await this.$router.push({query});
                this.updateSortingPreference(ctx)
            }
            await this.refresh(ctx);
        },
        async refresh(ctx = {}) {
            this.loading = true
            if ((Object.keys(ctx).length === 0)) {
                ctx.sortDesc = this.sortDesc_
                ctx.sortBy = this.sortBy_
            }
            this.items = await this.apiProvider(ctx);
            this.loading = false
            return this.items;
        },
        async routeAndRefresh(params, ctx) {
            let query = {...this.$route.query, ...params, ...this.filters}
            if (!isEqual(query, this.$route.query)) {
                await this.$router.push({query})
            }
        },
        async changePage(event, page) {
            this.currentPage = page;
            let query = {...this.$route.query, page}
            await this.$router.push({query})
            await this.refresh()
        },
        buildURL(itemData, urlTemplate = this.urlTemplate) {
            urlTemplate = window.location.origin + urlTemplate;
            const combinedData = {...itemData, ...this.data};
            Object.keys(combinedData).forEach((key) => {
                const value = encodeURIComponent(combinedData[key]);
                const pattern = new RegExp(`\\{${key}\\}`);
                urlTemplate = urlTemplate.replace(pattern, value);
            });
            return urlTemplate;
        },
        async updateBasedOnRouter(route) {
            let ctx = {}
            if (this.filters && Object.keys(this.filters).length > 0) {
                for (let key in this.filters) {
                    if (route.query[key] && route.query[key] !== this.filters[key]) {
                        ctx.filters = { [key]: route.query[key] }
                    }
                }
                this.filters_ = ctx.filters || this.filters
                this.$emit('update-filter', this.filters_)
            }
            // if they were on a different page
            let page;
            if (route.query.page && route.query.page !== this.currentPage) {
                page= parseInt(route.query.page);
            } else {
                page = 1;
            }
            ctx.currentPage = page;
            // if they had some sort of sorting, we convert sortDesc to a boolean from the string they pass in
            if ((route.query.sortDesc !== undefined && route.query.sortBy)) {
                this.sortDesc_ = (route.query.sortDesc === 'true');
                this.sortBy_ = route.query.sortBy;
            } else {
                this.sortDesc_ = '';
                this.sortBy_ = '';
            }

            await this.refresh(ctx);
            this.currentPage = page;
        },
        updateSortingPreference(ctx) {
            let params = { sorting_column : ctx.sortBy, sorting_order: ctx.sortDesc}
            UserService.updateSetting(this.userId, params).catch(e => {
                this.error = true;
                this.message = e.response.data.error;
            });
        },
        isReadyOrErr(status) {
            return status === "error" || status === "ready";
        }
    },
    watch: {
        $route: async function(to) {
            await this.updateBasedOnRouter(to);
        },
        searchQuery: debounce (function (newVal, oldVal) {
            this.refresh()
        }, 1000),
        perPage() {
            this.refresh()
        },
    },
    template: `
          <div v-if="!loading && items.length > 0">
          <slot name="table"
          :refresh="refresh"
          :routeAndRefresh="routeAndRefresh"
          :sortChanged="sortChanged"
          :buildURL="buildURL"
          :busy="loading"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :sortBy="sortBy_"
          :sortDesc="sortDesc_"
          >
             <b-table table-variant="light" head-variant="light" hover
              @sort-changed="sortChanged"
              :no-local-sorting="true"
              :no-sort-reset="true"
              id="list-table"
              :busy="loading"
              :items="items"
              :fields="fields"
              :current-page="currentPage"
            >
               <template v-slot:[linkCell]="data">
                 <a :href="buildURL(data.item)"> {{data.value}}</a>
               </template>
            </b-table>
          </slot>
          <div class="row justify-content-end">
            <div class="col-3 justify-content-end">
                <span>Rows per page:</span>
                <b-form-select class="w-auto border-0 bg-transparent pr-3" v-model="perPage" :options="perPageOptions" />
            </div>
            <b-pagination
            class="mb-5 pb-5 col-2 justify-content-end"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @page-click="changePage"
            first-number
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last" />
          </div>
  </div>
    `
}

export {BaseList};
