import { CampaignSitesRow } from './CampaignSitesRow.js';

const CampaignSitesConnection = {
    name: "CampaignSitesConnection",
    data() {
        return {
            selected: []
        }
    },
    methods: {
        toggleSelectAll(isChecked) {
            if (isChecked) {
                // Select all
                this.campaignsOptions.forEach(option => {
                    option.checked = true;
                });
            } else {
                // Deselect all
                this.selected = []
                this.campaignsOptions.forEach(option => {
                    option.checked = false;
                });
            }
        },
        updateSelected(detail, isSelected, campaignId = "") {
            if (isSelected) {
                // Check if the selected campaign exists in this.selected
                let index = this.selected.findIndex(campaign => campaign.campaignId === detail.campaignId);

                if (index > -1) {
                    // Campaign exists, compare and update
                    const existingCampaign = this.selected[index];
                    const updatedCampaign = { ...existingCampaign, ...detail }; // Merge existing and new properties
                    this.selected.splice(index, 1, updatedCampaign); // Replace the old object with the updated one
                } else {
                    // Campaign doesn't exist, add it
                    this.selected = [...this.selected, detail];
                }
            } else {
                // Remove the object with the matching campaignId from this.selected if it exists
                this.selected = this.selected.filter(campaign => campaign.campaignId !== campaignId);
            }
            this.$emit("updateSelected", this.selected)
        },

        // Get campaign object (connection data) that matched the campaignOption's id in ads' platform data
        getCampaignData(campaignOption) {
            if(!this.isAdsArray) {
                // Find the platform object with type 'gcm'
                const gcmData = this.ads.platforms.find(platform => platform.type === 'gcm')?.data;
                // Filter the array to find objects where campaign_id matches campaignOption.id
                if(!gcmData) return
                const matchedCampaignObject = gcmData.filter(campaign => campaign.campaign_id === campaignOption.id);
                // Return the array of matched campaign
                return matchedCampaignObject;
            }
        },
    },
    computed: {
        selectUnselectAllText() {
            // Dynamically determine the text based on whether all options are selected
            return this.isSelectedALL ? 'Un-select All' : 'Select All';
        },
        isSelectedALL() {
            // Compute whether all options are selected
            return this.campaignsOptions && this.selected.length === this.campaignsOptions.length;
        }
    },
    watch: {
        selected(newValue) {
            // Update parent selected array
            this.$emit("updateSelected", newValue)
        }
    },
    components: {
        CampaignSitesRow
    },
    props: {
        ads: [Object, Array],
        campaignsOptions: Array,
        groupLabel: String,
        valueField: String,
        sitesOptions: Array,
        isAdsArray: Boolean
    },
    template: `
    <b-form-group>
        <template #label>
            <div class="row">
                <p class="m-0 col-6">Campaign(s)</p>
                <p class="m-0 ml-5">Site(s)</p>
            </div>
            <b-form-checkbox
            :checked="isSelectedALL"
            @change="toggleSelectAll"
            aria-controls="campaigns"
            >
                <span class="font-weight-normal">{{ selectUnselectAllText }}</span>
            </b-form-checkbox>
        </template>

        <template class="w-100">
            <CampaignSitesRow v-for="(option, idx) in campaignsOptions" :campaign="option" :valueField="valueField" :key="option.value" :sitesOptions="sitesOptions" :idx="idx" @updateSelected="updateSelected" :connectionDetails="getCampaignData(option)" :isLastCampaign="idx === campaignsOptions.length - 1" />
        </template>
    </b-form-group>

    `,
}

export { CampaignSitesConnection }

