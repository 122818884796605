import router from '../routers/AdvertiserSettingsRouter.js';
import { Themes } from "../themes/Themes.js"
import { ThemesBreadcrumb } from "../themes/components/ThemesBreadcrumb.js"
import { DogPlusSvg } from "../tiers/components/DogPlusSvg.js"
import { store as themePreviewStore } from '../store/ThemePreviewStore.js'
import ApiService, { AssetsService } from "../common/api.service.js";
import { useIntegrationStore } from '../store/IntegrationStore.js';
import { mapState } from 'pinia';

const AdvertiserSettingsEntry = {
    name: "AdvertiserSettingsEntry",
    router,
    data() {
        return {
            themePreviewStore: themePreviewStore(),
            breadcrumbList: [],
            pageTitle: "",
            isHasImportSystemErrors: null,
            websiteImportType: null,
            dataSourceIntervalSelector: {},
            isBasicInfoReady: false,
            hasPlatformsActive: false
        }
    },
    props: {
        basic: Object, // Contain window.adretriever.Basic
        dataSource: Object,
        pixelAndConversions: Object,
        adminTools: Object,
        customThemes: Object,
    },
    components: {
        Themes,
        ThemesBreadcrumb,
        DogPlusSvg
    },
    methods: {
        updatePageTitle(title) {
            this.pageTitle = title
        },
        //Get the logo for theme list and theme preview page to use
        getLogo() {
            AssetsService.init(this.basic.websiteName).query({ only: themePreviewStore.logoDetailFields, type: 'logo', default: true })
                .then(response => {
                    if (!response.results.length) {
                        // Use default logo url if no uploaded logo
                        this.themePreviewStore.updateLogo({ asset: this.preset_logo_url });
                    } else {
                        this.themePreviewStore.updateLogo(response.results[0]);
                    }
                });
        },
        fetchBasicInfos() {
            // Fetching basic info for sidebar error display and props of child components
            ApiService.query(
                `websites/${this.basic.websiteName}`,
                { expand: 'data_import', only: 'data_import,import_type,interval,scrape_interval_day_week,scrape_interval_day_month' }
            )
                .then(response => {
                    this.websiteImportType = response.import_type
                    this.isHasImportSystemErrors = response.data_import?.latest_import_system_errors
                    this.dataSourceIntervalSelector = {
                            interval_selected: response.interval?.toString(),
                            day_week_selected: response.scrape_interval_day_week?.toString(),
                            day_month_selected: response.scrape_interval_day_month?.toString(),
                    }
                    this.constructIntervalSelectorProps()
                    this.getPlatforms()
                });
        },
        constructIntervalSelectorProps() {
            this.dataSource.intervalSelector = {
                websiteName: this.basic.websiteName,
                ...this.dataSourceIntervalSelector
            }
        },
        getPlatforms() {
            ApiService.doRequest('get', `../api/${this.basic.websiteName}/platforms/statuses/`, {}).then(response => {
                this.hasPlatformsActive = response.platforms.find(platform => platform.status === this.platformStatusMap.ENABLED);
                this.isBasicInfoReady = true
            })
        },
    },
    computed: {
        ...mapState(useIntegrationStore, ['platformStatusMap']),
        propsByRouteName() {
            const routeNameProps = this.$props[this.$route.name] || this.$props[this.$route.meta.propDataKey] || null
            // Merge basic props
            const routeNamePropsContainBasic = Object.assign({}, routeNameProps, this.basic)
            return routeNamePropsContainBasic
        },
        isCustomTheme() {
            return this.$route.meta.propDataKey === 'customThemes'
        }
    },
    mounted () {
        this.updatePageTitle(this.$route.meta.pageTitle)
        if(this.isCustomTheme) {
            this.getLogo()
        }
        this.fetchBasicInfos()
        this.getPlatforms()
    },
    watch: {
        '$route'() {
            if (this.isCustomTheme) {
                this.updatePageTitle(this.$route.meta.pageTitle)
            }
        }
    },
    template: `
    <div class="advertiser-setting-container" :class="{ 'themes-management': isCustomTheme }">
        <ThemesBreadcrumb />
        <div class="page-header mb-4 mt-0">
            <h4 class="pb-3">{{ pageTitle }}</h4>
        </div>
        <div class="row">
            <div class="col-3 border-right">
                <div class="list-group list-group-flush">
                    <router-link class="list-group-item" active-class="active" :to="{ name: 'general' }">General</router-link>
                    <router-link class="list-group-item d-flex justify-content-between align-items-center" active-class="active" :to="{ name: 'dataSource' }">
                        Data Source
                        <font-awesome-icon v-if="websiteImportType === 'None' || !websiteImportType" class="text-warning" :icon="['fas', 'triangle-exclamation']" />
                        <b-badge v-else-if="isHasImportSystemErrors" variant="danger" >Import failed</b-badge>
                    </router-link>
                    <router-link class="list-group-item d-flex justify-content-between align-items-center" active-class="active" :to="{ name: 'integrations' }">
                        Integrations
                        <font-awesome-icon v-if="isBasicInfoReady && !hasPlatformsActive" class="text-warning" :icon="['fas', 'triangle-exclamation']" />
                    </router-link>
                    <router-link class="list-group-item" active-class="active" :to="{ name: 'pixelAndConversions' }">Pixel & Conversions</router-link>
                    <router-link class="list-group-item" active-class="active" :to="{ name: 'adminTools' }">Admin Tools</router-link>
                    <router-link class="list-group-item" active-class="active" :to="{ name: 'autoBgRemoval' }">Auto Background Removal</router-link>
                    <router-link class="list-group-item custom-themes" active-class="active" :to="{ name: 'theme-versions-list' }">
                        Custom Themes <DogPlusSvg fillColorClass="teal-lk-bone" />
                    </router-link>
                </div>
            </div>
            <div class="col-9">
                <transition name="fade" mode="out-in">
                    <router-view 
                    v-if="isBasicInfoReady"
                    class="advertiser-setting-router-view" 
                    :key="$route.path" 
                    v-bind="{...propsByRouteName}"
                    :themePreviewStore="themePreviewStore"
                    v-on:updatePageTitle="updatePageTitle"></router-view>
                </transition>
            </div>
        </div>
    </div>
    `,
}

export { AdvertiserSettingsEntry }