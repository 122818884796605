import { CheckboxGroup } from "../../../common/components/CheckboxGroup.js";

const GoogleAds = {
    data: function () {
        return {
        }
    },
    props: {
        campaignsOptions: Array
    },
    methods: {

    },
    components: {
        CheckboxGroup
    },
    watch: {

    },
    template:
    `
    <div class="accordion" role="tablist">
        <b-card no-body class="mb-1 border-0 rounded-0">
            <b-card-header header-tag="header" v-b-toggle.campaigns-accordion class="p-2 border-0 rounded-0 grass-background d-flex justify-content-between align-item-center" role="tab">
                <p class="m-0 text-left text-white font-weight-600">Campaigns</p>
                <font-awesome-icon class="arrow-down light-sage-color align-self-center" :icon="['fas', 'chevron-down']" />
            </b-card-header>
            <b-collapse id="campaigns-accordion" visible role="tabpanel">
                <b-card-body>
                    <CheckboxGroup :options="campaignsOptions" groupLabel="Select Campaign(s)" ariaDescribedBy="campaigns" valueField="id" @updateSelected="updateConnectionDetails" />
                </b-card-body>
            </b-collapse>
        </b-card>
    
        <b-card no-body class="mb-1 border-0 rounded-0">
            <b-card-header header-tag="header" v-b-toggle.advertisers-accordion class="p-2 border-0 rounded-0 grass-background d-flex justify-content-between align-item-center" role="tab">
                <p class="m-0 text-left text-white font-weight-600">
                    Advertiser
                    <span v-b-tooltip.hover title="Check if you want your ad(s)s connect to campaign's advertiser. This is optional."><font-awesome-icon :icon="['fas', 'circle-info']"  /></span>
                </p>
                <font-awesome-icon class="arrow-down light-sage-color align-self-center" :icon="['fas', 'chevron-down']" />
            </b-card-header>
        </b-card>
    </div>
    `
}

export { GoogleAds };
