import {AdsService, AssetGroupService, VideoThemeFormService} from "./common/api.service.js";
import {VideoLibrary} from './videoLibrary/VideoLibrary.js'

let AdEditVideoDesigner = {
    data: function () {
        return {
            selected_template: false,
            error: false,
            message: '',
            isSaving: false,
            assetGroup: null, // Store asset from db record (show in edit mode)
            background_video_error: false,
            ad_name_error: false,
            adsService: AdsService.init(this.website),
            assetGroupService: AssetGroupService.init(this.website),
            // if not creating new ad, view only
            newAd: this.ad_id ? false : true,
            form: '<form><input type="text" id="choice_field" hidden/></form>',
            videoAssetGroup: null, // Keep updating base on user selection (aka VideoLibraryStore.assetGroups) in create mode
            showConfirmDialog: false,
        }
    },

    props: {
        store: Object,
        base_preset_preview_url: String,
        create_ad_url: String,
        returnURL: String,
        website: String,
        ad_id: String,
        fonts: Array,
        preset_logo_url: String,
        media_prefix: String,
        assetsURL: String,
        preset_asset_thumbnail: String,

    },

    components: {
        VideoLibrary
    },

    mounted: function () {
        this.updateForm();
        // if ad, get asset group id details
        this.ad_id ? this.getVideoAdDetails() : null
    },
    watch: {
        'store.ad.theme_config.theme_version': {
            deep: true,
            handler(newValue, oldValue) {
                this.$nextTick(() => {
                    this.updateForm(true);
                  });
            }
        }
    },
    updated() {
        const choiceField = this.$el.querySelector('#choice_field');
        this.fieldset_to_hide = choiceField.value;
    },
    methods: {
        updateForm(componentUpdate = false) {
            let videoThemeFormService = VideoThemeFormService.init(this.website);
            let promise;
            if (this.store.ad.theme_config.theme_version.id) {
                if (this.ad_id) {
                    // if ad_id is present, use ad to get the theme form and initial data for it
                    promise = videoThemeFormService.get_form_from_ad(this.ad_id);
                } else {
                    // if ad_id is not present, use the parent theme to get the theme form
                    promise = videoThemeFormService.get(this.store.ad.theme_config.theme_version.id);
                }
                promise
                    .then(data => {
                        this.form = data.form;
                        this.error = false;
                    })
                    .catch(data => {
                        this.form = '<form><input type="text" id="choice_field" hidden/></form>';
                        // If future we may want to display error message
                        this.error = true;
                    })
                    .finally(() => {
                        if (componentUpdate) {
                            this.$nextTick(() => {
                            this.$forceUpdate();
                          });
                        }
                    });
                }
            },
        swapFieldset(event) {
          let value = event.target.value;

          var fieldset_to_show = document.getElementById(value);
          if (fieldset_to_show && 'classList' in fieldset_to_show && fieldset_to_show.classList.contains('hidden')) {
              fieldset_to_show.classList.remove('hidden');
              var fieldset_to_hide = document.getElementById(this.fieldset_to_hide);
              fieldset_to_hide.classList.add('hidden');
              this.fieldset_to_hide = value;
            }
          },

        confirmSave(confirm) {
            this.showConfirmDialog = false;
            if (confirm) {
                this.performSave();
            }
        },

        saveAd() {
            if (this.ad_id) {
                this.showConfirmDialog = true;
            } else {
                this.performSave();
            }
        },

        performSave() {
            this.isSaving = true;
            if(this.videoAssetGroup && this.store.ad.name !== '') {
                let themeConfigData = {
                    'theme_version_id': this.store.ad.theme_config.theme_version.id,
                }
                const form = document.getElementById("form1");
                const inputs = form ? form.elements : [];
                let custom_theme_data = {}
                // Iterate over the form controls
                for (let input of inputs) {
                  // Check if the input's parent fieldset has the "hidden" class
                  const parentFieldset = input.closest('fieldset');
                  if (parentFieldset && parentFieldset.classList.contains('hidden')) {
                    // Skip this input element
                    continue;
                  }
                  // Include the input element in the custom_theme_data object
                if (input.type === 'checkbox') {
                    custom_theme_data[input.name] = input.checked;
                  }
                    else {
                    custom_theme_data[input.name] = input.value;
                  }
                }
                let formData = {
                    'name': this.store.ad.name,
                    'theme_config': themeConfigData,
                    'itemset': this.store.ad.itemset.id,
                    'asset_group_id': this.videoAssetGroup.id,
                    'type': 'video',
                    'custom_theme_data':custom_theme_data
                }
                // if we are editing, we want to update or else we create
                if (this.ad_id !== "") {
                    this.adsService.update(this.ad_id, formData, "video")
                    .then(response => {
                        window.location.href = this.returnURL;
                    })
                        .catch(response => {
                            this.isSaving = false
                            this.error = true;
                            this.message = 'An error has occurred'
                        });
                } else {
                    this.adsService.create(formData, "video")
                    .then(response => {
                        window.location.href = this.returnURL;
                    })
                        .catch(response => {
                            this.isSaving = false
                            this.error = true;
                            this.message = 'An error has occurred'
                        });
                }
            } else {
                this.isSaving = false
                this.videoAssetGroup ? this.background_video_error = false : this.background_video_error = true;
                this.store.ad.name !== '' ? this.store.changeAdNameErrorStatus(false) : this.store.changeAdNameErrorStatus(true)
            }
        },

        getVideoAdDetails() {
            // get asset specific data if editing ad
            this.adsService.get(this.ad_id, "", 'video')
            .then(response => {
                let asset_group_id = response.asset_group_id
                this.getVideoAdAsset(asset_group_id)
            })
        },
        
        getVideoAdAsset(asset_group_id) {
            this.assetGroupService.get(asset_group_id)
            .then(response => {
                this.assetGroup = response
            })
        },

        // For store to update the videoAsset (selected assetGroups inside VideoLibraryManagementTab, aka this.store.assetGroups)
        updateVideoAssetGroup(assetGroup){
            this.videoAssetGroup = assetGroup
        }
    },
    computed: {
        button_size: function() {
            // If we have a logo then we get the size props from the image to allow for scaling
            return this.store.getLogo() ? "" : "logo-button-size"
        },
        componentProps: function () {
            return {
                website: this.website,
                website_id: this.store.state.websiteId,
                preset_asset_thumbnail: this.preset_asset_thumbnail,
                itemAssetGroup: this.assetGroup
            }
        },
    },

    template:
        `
    <div class="video-edit row px-2">
        <div class="col-12">
            <div class="row design-section mb-4">
                <div class="col-12"> 
                    <div class="row w-100 border-bottom mb-3">
                        <h5>Customize</h5>
                    </div>
                    <VideoLibrary
                    class="video-library"
                    v-bind="{...componentProps}"
                    v-on:updateParentVideoAssetGroup="updateVideoAssetGroup"
                    />
                    <div class="row mt-2">
                        <p v-if="background_video_error" class="text-danger font-weight-bold">Please select a video background</p>
                    </div>
                </div>
            </div>  
            <div class="row design-section mb-4">
                <div class="col-12"> 
                    <div class="row w-100 border-bottom mb-3">
                        <h5>Theme Properties</h5>
                    </div>
                    <div class="row flex-column pr-4">
                        <div class="my-2">
                            <div v-html="form" v-on:change="swapFieldset" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="message!=='' && error" class="alert text-danger" v-bind:class="{ 'alert-success': !error, 'alert-error': error }" role="alert">{{ message }}</div>
            </div>
        </div>
        <div class="col-4 footer position-fixed border w-100 d-flex justify-content-center">
            <button class="btn btn-primary my-2 w-50" v-on:click="saveAd()" :disabled="isSaving">Save</button>
        </div>
      <div v-if="showConfirmDialog" class="modal" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Update</h5>
                </div>
                <div class="modal-body">
                    <p class="mb-0">Are you sure you want to update this ad?</p>
                    <p class="mt-2 mb-0"><strong>Important:</strong> Any changes made (except for renaming the ad) will regenerate all associated video content. This process may take some time and will replace the existing videos.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="confirmSave(false)">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="confirmSave(true)">Update</button>
                </div>
            </div>
        </div>
      </div>
    </div>
    `
    ,

}

export {AdEditVideoDesigner};
