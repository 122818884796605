
const DefaultTemplate = {
    name: "DefaultTemplate",
    data: function () {
        return {
            devWarning: false
        }
    },
    props: {
        websiteName: String,
        platform: Object
    },
    methods: {
        extractPlatformDataProperties() {
            const result = [];
            Object.keys(this.platform.data).forEach(key => {
                if (key.endsWith('_name')) {
                    const title = key.replace(/_name$/, ''); // Remove '_name' from the end of the key to get the title part
                    const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1); // Capitalize the first letter of the title for readability
                    result.push({
                        title: formattedTitle,
                        value: this.platform.data[key]
                    });
                }
            });
            return result;
        }
    },
    //TODO error state
    template:
    `
    <div class="default-template p-2">
        <h6>Status:
            <font-awesome-icon class="text-success ready-icon" :icon="['fas', 'circle-check']" />
            <span>Ready</span>
        </h6>

        <div v-for="field in extractPlatformDataProperties() " :key="field.title" class="border-bottom py-2 d-flex justify-content-between">
            <p class="mb-0">{{ field.title }}: </p>
            <p class="mb-0">{{ field.value }}</p>
        </div>
    </div>
    `
}

export { DefaultTemplate };
