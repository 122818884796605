import ApiService from '../common/api.service.js';
import { StatusAlert } from "../common/components/StatusAlert.js"
import { IntegrationSwitch } from './components/IntegrationSwitch.js';
import { IntegrationModal } from './components/IntegrationModal.js';
import { IntegrationsService } from '../common/api.service.js';
import { useIntegrationStore } from '../store/IntegrationStore.js';
import { mapState } from 'pinia';

const Integrations = {
    name: "Integrations",
    props: {
        staticUrl: String,
        websiteName: String,
        hasMetaTesterRole: Boolean,
    },
    data() {
        return {
            isShowDisableConfirmation: false,
            platforms: [],
            gcmHostValue: null,
            isSuccess: false,
            responseMsg: null,
            isShowAlert: false,

            integrationModal: {
                isShow: false,
                platform: null,
                isConnect: null,
                oauthUrl: null
            },
            integrationsService: null,
            isShowedModalOnce: false, // For stop calling openSpecificPlatformModal again for having platformType as route params
            // Polling to get status until all platform are not in enabling
            pollingInterval: 10000, // 10 seconds
            polling: null,
        }
    },
    methods: {
        resetAlert() {
            this.isShowAlert = false
            this.responseMsg = null
        },
        showSuccessAlert(message) {
            this.isSuccess = true
            this.isShowAlert = true
            this.responseMsg = message
            this.getPlatforms() // Ensure all platform details correct
        },
        showErrorAlert() {
            this.isSuccess = false
            this.isShowAlert = true
            this.responseMsg = null
        },
        enablePlatform(type) {
            window.location.href = `./integrations/${type}/enable`
        },
        getPlatforms() {
            ApiService.doRequest('get', `../api/${this.websiteName}/platforms/statuses/`, {}).then(response => {
                this.platforms = response.platforms

                // Handle having platformType as route params, open integration modal
                if (this.$route.params?.platformType && !this.isShowedModalOnce) {
                    this.openSpecificPlatformModal()
                } else if(this.isShowedModalOnce) {
                    this.$router.push({ name: 'integrations' }) // Push to integration without platform type to prevent user got stuck in page with params but the modal never open again
                } 

                // Set up polling if any of the platforms is enabling
                if (this.isAnyPlatformEnabling) {
                    if (this.polling) return
                    this.polling = setInterval(() => {
                        this.getPlatforms();
                    }, this.pollingInterval);
                }else{ // Clear interval if no platforms is enabling
                    clearInterval(this.polling);
                }
            })
        },
        showIntegrationModal(platform, isConnect) {
            this.integrationModal.isShow = true
            this.integrationModal.isConnect = isConnect
            this.integrationModal.platform = platform || ""
        },
        hideIntegrationModal(isCancelled = false) {
            this.integrationModal.isShow = false
            this.integrationModal.isConnect = null
            if(!isCancelled) this.getPlatforms()
        },
        openSpecificPlatformModal() {
            if(!this.isShowedModalOnce) this.isShowedModalOnce = true
            // Get platform object by this.$route.params.platformType
            const platformObject = this.platforms.find(platform => platform.type === this.$route.params.platformType);
            // Do not show modal the platform does not need to config
            if(platformObject.status !== this.platformStatusMap.NEEDS_CONF) {
                this.$router.push({ name: 'integrations' }) // Push to integration without platform type to prevent user got stuck in page with params but the modal never open again
            }
            this.showIntegrationModal(platformObject, !platformObject.enabled)
        },
        init() {
            this.integrationsService = new IntegrationsService(this.websiteName)
            this.getPlatforms()
        }
    },
    destroyed () {
        clearInterval(this.polling);
        this.isShowedModalOnce = false
    },
    computed: {
        ...mapState(useIntegrationStore, ['platformStatusMap']),
        integrationModalProps() {
            return {
                websiteName: this.websiteName,
                platform: this.integrationModal.platform,
                isShow: this.integrationModal.isShow,
                isConnect: this.integrationModal.isConnect,
                oauthUrl: this.integrationModal.oauthUrl,
                staticUrl: this.staticUrl,
                integrationsService: this.integrationsService
            }
        },
        integrationSwitchProps() {
            return {
                staticUrl: this.staticUrl,
                websiteName: this.websiteName,
                integrationsService: this.integrationsService
            }
        },

        isAnyPlatformEnabling() {
            return this.platforms.some(platform => platform.status === this.platformStatusMap.ENABLING)
        },
    },
    components: {
        StatusAlert,
        IntegrationSwitch,
        IntegrationModal
    },
    mounted() {
        this.init()
    },
    template: `
    <div class="integrations-container">
        <h5 class="mb-3">Integration</h5>
        <StatusAlert :isSuccess="isSuccess" :successMessage="responseMsg" :isShowAlert="isShowAlert" />
        <b-alert show variant="success">
            <font-awesome-icon class="mr-1" :icon="['fas', 'circle-info']" />
            Our integrations allow you to push your ads and item details directly to the platforms you work with.
        </b-alert>
        <div class="container mb-5">
            <div class="row justify-content-between">
                <IntegrationSwitch v-for="platform in platforms" :platform="platform" :key="platform.type" v-bind="{...integrationSwitchProps}" @showIntegrationModal="showIntegrationModal" @getPlatforms="getPlatforms"/>
            </div>
        </div>
        <IntegrationModal v-bind="{...integrationModalProps}" @hideModal="hideIntegrationModal" @showSuccessAlert="showSuccessAlert"  />
    </div>
    `
}

export { Integrations };