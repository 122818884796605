import { useIntegrationStore } from "../../../store/IntegrationStore.js";
import { mapActions, mapState } from 'pinia';

const Meta = {
    name: "MetaTemplate",
    data() {
        return {
            connected: null,
            adRecordConnectStatus: null,
        }
    },
    props: {
        ads: [Object, Array],  // Array of objects if we need to connect more than one ads to the platform
    },
    methods: {
        ...mapActions(useIntegrationStore, [
            'updateConnectionDetails'
        ]),
        // The details later will be sending to connection API
        updateConnectionDetailsInStore() {
            if(Array.isArray(this.ads)){
                // We do not need to send any data for bulk connect
                this.updateConnectionDetails(null, this.isAllConnectionDetailsValid, this.connected)
            }else{
                // Check if connect / disconnect
                if(this.connected) {
                    this.updateConnectionDetails( ["enabled"], this.isAllConnectionDetailsValid, this.connected)
                }else{
                    // We will need to send empty array for disconnect
                    this.updateConnectionDetails( [], this.isAllConnectionDetailsValid, this.connected)
                }
            }
        },
        updateIsConnectStatus() {
            // Only display connect status for single ad
            if(!this.isAdsArray) {
                // Find the platform object with type 'meta'
                const metaConnectedData = this.ads.platforms.find(platform => platform.type === this.platformNameMap.META)?.data;
                // If found, means this ad has connected to meta before
                this.connected = this.adRecordConnectStatus = !!metaConnectedData?.length;
            }
        }
    },
    watch: {
        connected(newValue, oldValue) {
            this.updateConnectionDetailsInStore()
        }
    },
    computed: {
        ...mapState(useIntegrationStore, [
            'platformNameMap'
        ]),
        statusDisplay() {
            return this.connected ? "connected" : "not connected"
        },
        isAllConnectionDetailsValid() {
            return this.connected !== this.adRecordConnectStatus
        }
    },
    mounted () {
        this.updateConnectionDetailsInStore();
        this.updateIsConnectStatus()
    },
    template:
    `
    <div class="d-flex">
        <p class="font-weight-bold spruce-color  mr-2">Connection status:</p>
        <b-form-checkbox
        v-model="connected"
        >
            <span class="text-capitalize font-weight-normal">{{ statusDisplay }}</span>
        </b-form-checkbox>
    </div>
    `
}

export { Meta };
