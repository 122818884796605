// Row action to connect specific platform
const ConnectionToggle = {
    data: function () {
        return {
            checked: null
        }
    },
    props: {
        platform: Object,
        ad: Object
    },
    methods: {
        onChangeSwitch(){
            const modalType = this.checked ? 'connect' : 'disconnect'
            this.$emit("showConnectionModal", this.ad , modalType, this.platform)
        },
        updateCheckedValue(value) {
            this.checked = value
        },
        checkIfAdConnected() {
            // Check if any platform object in this.ad.platforms has a type matching this.platform.type
            const isConnected = this.ad.platforms.some(platform => platform.type === this.platform.type);
            this.updateCheckedValue(isConnected)
        },
        onClickDropdownItem() {
            if(!this.checked) return
            this.$emit("showConnectionModal", this.ad , 'connect', this.platform)
        }
    },
    mounted () {
        this.checkIfAdConnected();
    },
    template:
    `   
    <div>
        <div class="dropdown-item row no-gutters" @click="onClickDropdownItem">
            <label class="ml-auto mb-0 col-11 font-weight-normal">{{ platform.name }}</label>
            <b-form-checkbox v-model="checked" name="check-button" switch size="sm" class="d-inline col-1" @change.native="onChangeSwitch" />
        </div>
    </div>
    `
}

export { ConnectionToggle };
